import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { format } from "date-fns";
import MiniMap from "../../../components/Base/MiniMap";

import { IconButton } from "@material-ui/core";

import PublicIcon from "@material-ui/icons/Public";
import MapIcon from "@material-ui/icons/Map";

import Table from "../../../components/Base/Table";

import { GetDateFormat, secondsToTime } from "../../../utils/generalUtils";

import { useTranslation } from "../../../lang";
import { TABLE_COLORS } from "../../../Theme";

const ReportResult = ({ reportData, user }) => {
  const [t] = useTranslation();
  const [detalle, setDetalle] = useState();
  const [rowSelected, setRowSelected] = useState(0);
  const [miniMapData, setMiniMapData] = useState({
    lat: 0,
    lon: 0,
    title: "",
    tooltipText: "",
  });

  const [isMiniMapOpen, setIsMiniMapOpen] = useState(false);
  const closeMiniMap = () => setIsMiniMapOpen(false);

  useEffect(() => {
    setDetalle(reportData[0].eventReports);
  }, [reportData]);

  const summaryRowEvents = {
    onClick: (e, row, rowIndex) => {
      const assetData = reportData.find((rd) => rd.mid === row.mid);
      setDetalle(assetData.eventReports);
      setRowSelected(rowIndex);
    },
  };

  const handleOpenMiniMap = (row) => {
    setMiniMapData({
      lat: row.lat,
      lon: row.lon,
      title: t("Evento"),
      tooltipText: row.evtDesc,
    });
    setIsMiniMapOpen(true);
  };

  const columnsSummary = [
    {
      dataField: "name",
      text: t("Móvil"),
      sort: true,
      headerStyle: () => ({ width: "20%" }),
    },
    {
      dataField: "pat",
      text: t("Placa"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "iTime",
      text: t("Desde"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.iTime), GetDateFormat(user.language)),
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "fTime",
      text: t("Hasta"),
      sort: true,
      formatter: (cell, row) =>
        format(new Date(row.fTime), GetDateFormat(user.language)),
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "traveltime",
      text: t("Tiempo Recorrido"),
      sort: true,
      formatter: (cell, row) => secondsToTime(row.traveltime),
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "numEvents",
      text: t("Eventos Totales"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "id",
      text: t("KML"),
      headerStyle: () => ({ width: "50px" }),
      formatter: (cell, row) => (
        <IconButton
          style={{ padding: "0" }}
          onClick={() => handleOpenMiniMap(row)}
        >
          <PublicIcon style={{ color: "white" }} />
        </IconButton>
      ),
    },
  ];

  const columnsDetail = [
    {
      dataField: "fGPS",
      text: t("Fecha"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => {
        console.log("row.fGPS:", row.fGPS);
        return format(new Date(row.fGPS), GetDateFormat(user.language));
      },
    },
    {
      dataField: "traveltime",
      text: t("Tiempo Recorrido"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
      formatter: (cell, row) => secondsToTime(row.traveltime),
    },
    {
      dataField: "kmsRec",
      text: t("Kms Recorridos"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "evtDesc",
      text: t("Eventos"),
      sort: true,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "nCh",
      text: t("Conductor"),
      sort: true,
      headerStyle: () => ({ width: "10%" }),
    },
    {
      dataField: "dir",
      text: t("Lugar"),
      sort: true,
      headerStyle: () => ({ width: "25%" }),
    },
    {
      dataField: "id",
      text: t("Ver"),
      sort: true,
      headerStyle: () => ({
        width: "50px",
        fontSize: "12px",
        textAlign: "center",
      }),
      formatter: (cell, row) => (
        <IconButton
          style={{ padding: "0" }}
          onClick={() => handleOpenMiniMap(row)}
        >
          <MapIcon style={{ color: "white" }} />
        </IconButton>
      ),
    },
  ];

  const rowSummaryStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex === rowSelected) {
      // style.background = "#1c3663";
      style.background = TABLE_COLORS.ROW_SELECTED;
      style.color = TABLE_COLORS.ROW_SELECTED_TEXT;
    }

    return style;
  };

  return (
    <div>
      <MiniMap
        isOpen={isMiniMapOpen}
        closeModal={closeMiniMap}
        {...miniMapData}
      />
      <div className="resultPart">
        <Table
          columns={columnsSummary}
          data={reportData}
          keyField="mid"
          rowEvents={summaryRowEvents}
          rowStyle={rowSummaryStyle}
        />
      </div>
      <div className="resultPart">
        <Table columns={columnsDetail} data={detalle} keyField="id" />
      </div>
    </div>
  );
};

const _mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

const _mapDispatchToProps = (dispatch) => {
  return {
    // _setCurrentUser: (param) => dispatch(setCurrentUser(param)),
  };
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ReportResult);
