import gql from "graphql-tag";

const GET_EVENTREPORT_QUERY = gql`
  query EventReport(
    $assetsSelected: [Int]!
    $driversSelected: [Int]
    $typeEventSelected: [Int]
    $fechaInicio: Datetime
    $fechaFin: Datetime
    $days: [WeekDaysEnum]
  ) {
    eventReport(
      assetsSelected: $assetsSelected
      driversSelected: $driversSelected
      typeEventSelected: $typeEventSelected
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      days: $days
    ) {
      mid
      name
      pat
      iTime
      fTime
      traveltime
      numEvents
      eventReports {
        fGPS
        traveltime
        kmsRec
        evtDesc
        nCh
        dir
        lat
        lon
      }
    }
  }
`;

export default GET_EVENTREPORT_QUERY;
